import React, { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { calcStyle } from '@legacyApp/client/modules/style/methods/calcStyle';
import { chatViewport } from '@legacyApp/client/modules/style/methods/chatViewport';
import { trans } from '@legacyApp/client/modules/translation/translate';

import {
	ScrollCardsWithTitle,
	StyledScrollCardsHeader,
	StyledScrollCardsTitle,
	StyledScrollCardsWithTitle,
} from '@common/components/layout/ScrollCards/ScrollCardsWithTitle';
import { StyledSlotsScrollCardsTailWrapper } from '@modules/slots/components/SlotsScrollCards';
import { SlotTile } from '@modules/slots/components/SlotTile';

export enum GameSliderId {
	RECENTLY_PLAYED = 'recently_played',
	BIGGEST_WINS = 'biggest_wins',
	TRENDING = 'trending',
}

export interface GamesSliderBoxProps {
	title: ReactNode;
	list: any[];
	loaded: boolean;
}

export const GamesSliderBox: FC<GamesSliderBoxProps> = ({
	title,
	list,
	loaded,
}) => {
	if (!loaded || !list?.length) {
		return;
	}

	return (
		<GameSliderBoxStyled>
			<ScrollCardsWithTitle
				name={<span className="text-style-sm-semibold">{title}</span>}
				loaded={loaded}
				noDataContent={
					<span className="text-style-sm-medium">
						{trans({ label: 'No games' })}
					</span>
				}
				list={list?.map?.((slot, index) => {
					return {
						name: slot.name,
						component: (
							<StyledSlotsScrollCardsTailWrapper key={`${index}-${slot.slug}`}>
								<SlotTile {...slot} />
							</StyledSlotsScrollCardsTailWrapper>
						),
					};
				})}
			/>
		</GameSliderBoxStyled>
	);
};

export const GameSliderBoxStyled = styled.div`
	background-color: var(--color-dark-500);
	border-radius: 24px;
	padding: 16px 16px 9px;

	${StyledScrollCardsWithTitle} {
		margin-bottom: 0;

		.scroll-menu-arrow {
			top: -44px;
		}

		${StyledScrollCardsHeader} {
			margin-bottom: 16px;

			${StyledScrollCardsTitle} {
				margin-bottom: 0;
			}
		}
	}

	${chatViewport(
		(viewportPadding) => css`
			@media (min-width: ${(props) =>
					calcStyle({
						a: props.theme.media.minWidthDesktop,
						b: viewportPadding,
					})}) {
				${StyledSlotsScrollCardsTailWrapper} {
					margin-right: 26px;
				}
			}
		`,
	)};
`;
